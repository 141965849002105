import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Background from "./../../../components/Background.js"
import Content from "./../../../components/page/candidate/Content.js"

export default function Home(props, { data }) {
  const name = props.name ? props.name.split("_").slice(0, 2) : []
  const [candidate, setCandidate] = useState()

  useEffect(() => {
    async function fetchData() {
      var candidate = await fetchCandidate(
        capitalizeFirstLetter(name[0]),
        capitalizeFirstLetter(name[1])
      )
      candidate = candidate[0]
      candidate.picture.url = await fetchImage(candidate.picture.uploadId)

      setCandidate(candidate)
    }

    fetchData()
  }, [])

  if (!candidate) {
    return (
      <div className="flex flex-col  justify-center ">
        <img
          className="w-96 m-auto"
          alt={name[0] + " " + name[1]}
          src="./../loading-animation-2.gif"
        />
      </div>
    )
  }

  return (
    <div>
      <Background>
        <Page candidate={candidate} data={data} />
      </Background>
    </div>
  )
}

function capitalizeFirstLetter(string) {
  const names = string
    ? string
        .split("-")
        .map(name => name.charAt(0).toUpperCase() + name.slice(1))
    : [""]
  return names.join("-")
}

async function fetchImage(id) {
  const { SiteClient } = require("datocms-client")
  const client = new SiteClient("7617f586a3abb029f54ca4026bcae5")

  const image = await client.uploads.find(id)
  return image.url
}

async function fetchCandidate(name, surname) {
  const { SiteClient } = require("datocms-client")
  const client = new SiteClient("7617f586a3abb029f54ca4026bcae5")

  return client.items.all({
    filter: {
      type: "candidate",
      fields: {
        name: {
          eq: name,
        },
        surname: {
          eq: surname,
        },
      },
    },
  })
}

function Page({ data, candidate }) {
  return (
    <div className="-my-36 md:w-4/5  md:-my-56 xl:max-w-7xl">
      <Logo />
      <Content candidate={candidate} />
    </div>
  )
}

function Logo({ logo }) {
  return <GatsbyImage className="w-36 " alt="Liike Nyt logo" image={logo} />
}

export const query = graphql`
  query {
    datoCmsWebsiteFile {
      logo {
        gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
      }
    }
  }
`
